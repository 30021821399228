<template>
  <div class="taskOrderTheList flex m-10">
    <template v-for="option in options" :key="option.id">
      {{ option.content }}
      <draggable
        class="dragArea list-group w-full"
        :list="option.values"
        @change="itemChanged(option.id, $event)"
      >
        <div
          class="list-group-item bg-gray-300 m-1 p-3 rounded-md"
          v-for="element in option.values"
          :key="element.content"
        >
          <div class="content">
            <VerticalDragArrows />
            <span>{{ element.content }}</span>
          </div>
        </div>
      </draggable>
    </template>
  </div>
</template>
<script>
import { ref, computed, onBeforeMount } from "vue";
import VerticalDragArrows from "./order-the-list/VerticalDragArrows";
import { VueDraggableNext } from "vue-draggable-next";
import { AnswerHandler } from "@moods/taitaen-core";

export default {
  name: "OrderTheList",
  components: {
    draggable: VueDraggableNext,
    VerticalDragArrows,
  },
  props: ["data", "isTest", "taskId"],
  setup(props) {
    const { createAnswer } = AnswerHandler();
    const answers = ref(createAnswer(props.taskId));
    const options = ref([]);

    const enabled = ref(true);
    const dragging = ref(false);

    onBeforeMount(() => {
      if (props.data.options) {
        options.value = props.data.options;
      }

      if (answers.value.length === 0) {
        createAnswers(options);
      } else {
        orderTheListByAnswers();
      }
    });

    function createAnswers() {
      let option;
      for (let i = 0; i < options.value.length; i++) {
        option = options.value[i];
        answers.value.push({
          id: option.id,
          value: options.value[i].values.map((obj) => obj.value),
        });
      }
    }

    function orderTheListByAnswers() {
      let option, newValues;
      for (let i = 0; i < answers.value.length; i++) {
        // Get option
        option = options.value.find(({ id }) => id === answers.value[i].id);

        // Set new values
        newValues = {};
        for (let j = 0; j < option.values.length; j++) {
          newValues[option.values[j].value] = option.values[j];
        }

        // Set new values by answer value
        option.values = [];
        for (let j = 0; j < answers.value[i].value.length; j++) {
          option.values.push(newValues[answers.value[i].value[j]]);
        }
      }
    }

    function itemChanged(option_id) {
      // Update answers
      let option = options.value.find(({ id }) => id === option_id);
      let answer = answers.value.find(({ id }) => id === option_id);
      answer.value = option.values.map((obj) => obj.value);
    }

    return {
      enabled: computed(() => enabled.value),
      options: computed(() => options.value),
      dragging: computed(() => dragging.value),
      itemChanged,
    };
  },
};
</script>
<style lang="scss" scoped>
.taskOrderTheList {
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .sortable-ghost {
    opacity: 0.5;
    background: var(--theme-color-first);
  }
  .list-group-item {
    color: var(--theme-color-second);
    cursor: move;
    /*border-radius: 10px;*/
    font-weight: bold;
    border: 1px solid var(--theme-color-second);
    border-radius: 0;

    /*
        &:first-child {
            border-radius: 10px;
        }
        &:last-child {
            border-radius: 10px;
        }
        */
  }
  .list-group-item i {
    cursor: pointer;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      width: 100%;
    }
  }
  .arrows {
    //position: absolute;
    //left: 20px;
    display: inline-block;
  }
}
</style>